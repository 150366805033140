<template>
  <div id="app" :style="cssProps">
    <div class="content">
      <NavBarTop class="NavBarTop p-0" v-if="!$route.meta.hideNavbarTop" />
      <NavBar class="mb-2 py-1" />
      <div class="container">
        <transition name="slide-fade" mode="out-in">
          <router-view :key="$route.fullPath" />
        </transition>
      </div>
      <a v-if="showScrollButton" href="#" id="return-top" v-scroll-to="'#app'">
        <BaseIcon name="arrow-up-circle" width="40" height="40" />
      </a>
      <Footer />
    </div>
  </div>
</template>

<script>
import NavBarTop from "@/components/header_top.vue";
import NavBar from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import DataService from "@/services/DataService.js";
import axios from "axios";
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  name: "main-app",
  components: {
    NavBarTop,
    NavBar,
    Footer,
  },
  data() {
    return {
      scrollHeight: 0,
      interval: null,
      showScrollButton: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    if (this.status) {
      this.companyData.languages.forEach(function (index) {
        if (index != "") {
          axios
            .get("/locale/" + index + ".json?v=" + Date.now() + "")
            .then((response) => {
              Vue.i18n.add(index, response.data);
            });
        }
      });
      if (!Vue.i18n.locale()) {
        Vue.i18n.set(this.companyData.defaultLanguage);
      }
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.top.scrollY > 400) {
        this.showScrollButton = true;
      } else {
        this.showScrollButton = false;
      }
    },
    getHeaderColor(hexcolor) {
      hexcolor = hexcolor.replace("#", "");
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "#111111" : "#cccccc";
    },
    theme(hexcolor) {
      hexcolor = hexcolor.replace("#", "");
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "0" : "255";
    },
  },
  computed: {
    ...mapGetters(["companyData"]),
    status() {
      return !!this.companyData.shopBackgroundColorRgb;
    },
    cssProps() {
      var themeProbs = 0;
      if (this.status) {
        var hexn = this.theme(this.companyData.shopBackgroundColorRgb);
        themeProbs = {
          "--navbgcolor": this.companyData.shopHeaderColorRgb,
          "--bodybgcolor": this.companyData.shopBackgroundColorRgb,
          "--shopaccent1": this.companyData.shopAccentColor1,
          "--shopaccent2": this.companyData.shopAccentColor2,
          "--shopaccent3": this.getHeaderColor(
            this.companyData.shopAccentColor2
          ),
          "--shopaccent4": this.companyData.shopGroupButtonsColorRgb,
          "--shopaccent5": this.getHeaderColor(
            this.companyData.shopGroupButtonsColorRgb
          ),
          "--bodybgtile":
            "url(" + JSON.stringify(this.companyData.shopBackgroundImage) + ")",
          "--textcolor": this.getHeaderColor(
            this.companyData.shopBackgroundColorRgb
          ),
          "--panel": "rgba(" + hexn + ", " + hexn + ", " + hexn + ", 0.1)",
          "--panel1": "rgba(" + hexn + ", " + hexn + ", " + hexn + ", 0.02)",
          "--panel2": "rgba(" + hexn + ", " + hexn + ", " + hexn + ", 0.03)",
          "--panel3": "rgba(" + hexn + ", " + hexn + ", " + hexn + ", 0.04)",
        };
      } else {
        themeProbs = {
          "--navbgcolor": "#161616",
          "--bodybgcolor": "#ffffff",
          "--shopaccent1": "#252525",
          "--shopaccent2": "#454545",
          "--shopaccent3": "#cccccc",
          "--shopaccent4": "#656565",
          "--shopaccent5": "#000000",
          "--bodybgtile": "none",
          "--textcolor": "#161616",
          "--panel": "rgba(0, 0, 0, 0.1)",
          "--panel1": "rgba(0, 0, 0, 0.02)",
          "--panel2": "rgba(0, 0, 0, 0.03)",
          "--panel3": "rgba(0, 0, 0, 0.04)",
        };
      }
      return themeProbs;
    },
    siteName() {
      return this.companyData.companyName;
    },
    siteDescription() {
      return this.companyData.shopDescription;
    },
  },
  beforeMount() {
    if (this.companyData.length != 0) {
      DataService.setStore(this.companyData.apiKey);
    }
  },
};
</script>
