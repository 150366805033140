<template>
  <div>
    <div v-if="inputStore">
      <button
        class="btn btn-lg btn-primary btn-block m-0"
        @click="goBack()"
      >
        {{ $t("Zur Karte") }}
      </button>
    </div>
    <div v-else>
      <div>
        <h2 class="text-center">
          {{ $t("Zur Karte scannen Sie bitte den Tisch QRCode") }}
        </h2>
        <div class="row">
          <div class="col-lg-12">
            <b-form @submit.prevent="submitApiKey">
              <div class="panel t1 p-2 mb-4">
                <div class="scanWindow">
                  <qrcode-stream @decode="onDecode" />
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { QrcodeStream } from "vue-qrcode-reader";
import axios from "axios";
import Vue from "vue";

export default {
  name: "page-stores",
  data() {
    return {
      companies: [],
      inputStore: true,
      result: "",
      error: "",
    };
  },
  components: {
    QrcodeStream,
  },
  methods: {
    ...mapGetters(["companyData"]),
    onDecode(result) {
      this.result = result;
      var arr = this.result.split(",");
      const payload2 = {
        apikey: arr[0],
        allow_stores: true,
        tableNumber: arr[1],
      };
      this.$store
        .dispatch("setStore", payload2)
        .then(this.$router.push({ name: "order" }));
    },
    goBack() {
      this.$router.push({ name: "order" });
    },
  },
  created() {
    const inStore = localStorage.getItem("company");
    if (inStore && inStore != 0) {
      this.inputStore = true;
    } else {
      this.inputStore = false;
    }


    Vue.i18n.set(this.$root.default_lang);
    if (this.$route.query.lang) {
      axios
        .get("/locale/" + this.$route.query.lang + ".json?v=" + Date.now() + "")
        .then((response) => {
          Vue.i18n.set(this.$route.query.lang);
          Vue.i18n.add(this.$route.query.lang, response.data);
        });
    } else {
      axios
        .get(
          "/locale/" + this.$root.default_lang + ".json?v=" + Date.now() + ""
        )
        .then((response) => {
          Vue.i18n.set(this.$root.default_lang);
          Vue.i18n.add(this.$root.default_lang, response.data);
        });
    }
  },
  beforeMount() {
  }
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.scanWindow {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
  border: 1px solid #cccccc;
  background-color: #3e3c49;
}
</style>
