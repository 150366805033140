<template>
  <div class="icon-wrapper">
    <svg class="icon" :width="width" :height="height">
      <use
        v-bind="{ 'xlink:href': '/assets/img/feather-sprite.svg#' + name }"
      />
    </svg>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "app-baseicon",
  props: {
    name: String,
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>

<style scoped></style>
