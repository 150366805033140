<template>
  <div>
    <h1>{{ $t("Neue Adresse") }}</h1>
    <div class="row">
      <div class="col-lg-12">
        <div class="alert alert-danger" v-if="error">
          {{ $t(error) }}
        </div>
        <div class="alert alert-success" v-if="success">
          {{ $t(success) }}
        </div>
        <b-form @submit.prevent="newAddress">
          <div class="panel p-2">
            <div class="row">
              <div class="col-lg-6">
                <h5>{{ $t("Vorname") }}:</h5>
                <b-form-input
                  v-model="firstName"
                  name="firstName"
                  autocomplete="off"
                  required
                  value
                ></b-form-input>
              </div>
              <div class="col-lg-6">
                <h5>{{ $t("Nachname") }}:</h5>
                <b-form-input
                  v-model="lastName"
                  name="lastName"
                  autocomplete="off"
                  required
                  value
                ></b-form-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <h5>{{ $t("PLZ") }}:</h5>
                <b-form-input
                  v-model="postCode"
                  name="postCode"
                  autocomplete="off"
                  required
                  value
                ></b-form-input>
              </div>
              <div class="col-lg-9">
                <h5>{{ $t("Stadt") }}:</h5>
                <b-form-input
                  v-model="city"
                  name="city"
                  autocomplete="off"
                  required
                  value
                ></b-form-input>
              </div>
            </div>
            <h5>{{ $t("Strasse") }}:</h5>
            <b-form-input
              v-model="street"
              name="street"
              autocomplete="off"
              required
              value
            ></b-form-input>
            <h5>{{ $t("Adress Zusatz") }}:</h5>
            <b-form-input
              v-model="address"
              name="address"
              autocomplete="off"
              value
            ></b-form-input>
            <h5>{{ $t("Telefon") }}:</h5>
            <b-form-input
              v-model="phone"
              name="phone"
              autocomplete="off"
              value
            ></b-form-input>
            <div class="text-right">
              <button
                class="btn btn-md btn-right btn-primary mt-2 mx-0"
                type="submit"
                name="button"
              >
                {{ $t("Adresse Hinzufügen") }}
              </button>
            </div>
          </div>
        </b-form>
        <button
          class="btn btn-lg btn-block btn-primary mt-3 mx-0"
          type="button"
          @click="$router.go(-1)"
        >
          {{ $t("Zurück") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DataService from "@/services/DataService";
export default {
  name: "page-newaddress",
  data() {
    return {
      lastName: "",
      firstName: "",
      password: "",
      street: "",
      address: "",
      postCode: "",
      phone: "",
      city: "",
      error: null,
      success: null,
    };
  },

  computed: {
    ...mapGetters(["userData"]),
  },
  methods: {
    newAddress() {
      if (
        this.lastName != "" &&
        this.firstName != "" &&
        this.street != "" &&
        this.postCode != "" &&
        this.phone != "" &&
        this.city != ""
      ) {
        DataService.userAddress({
          lastName: this.lastName,
          firstName: this.firstName,
          street: this.street,
          address: this.address,
          postCode: this.postCode,
          phone: this.phone.replace(/[- )(]/g, ""),
          city: this.city,
        })
          .then(() => {
            this.success = "Update Erfolgreich!";
            this.error = false;
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000);
          })
          .catch((err) => {
            this.error = err.response.data;
          });
      } else {
        this.error = "Error!";
      }
    },
    goBack() {
      setTimeout(() => {
        this.$router.go(-1);
      }, 300);
    },
  },
};
</script>

<style scoped>
h5 {
  margin-top: 5px;
}
</style>
