<template>
  <b-navbar
    type="dark"
    fixed="top"
    class="relative"
    data-toggle="collapse"
    data-target="#navi"
  >
    <!-- Brand Center -->
    <b-navbar-brand class="navbar-brand mx-auto" to="/">
      <img v-bind:src="logoUrl" />
    </b-navbar-brand>
    <!-- Right aligned nav items -->
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "app-headertop",
  components: {},
  computed: {
    ...mapGetters(["companyData"]),
    logoUrl() {
      return this.companyData.shopHeaderImage;
    },
  },
};
</script>

<style scoped>
.relative {
  position: relative;
}
</style>
