<template>
  <div>
    <h2>{{ $t("Konto") }}</h2>
    <div class="row">
      <div class="col-lg-12">
        <div class="panel p-2">
          <div class="alert alert-danger" v-if="error">
            {{ $t(error) }}
          </div>
          <div class="alert alert-success" v-if="success">
            {{ $t(success) }}
          </div>
          <form @submit.prevent="updateUser" autocomplete="off">
            <h5>Email:</h5>
            <h6 class="ml-3">{{ name }}</h6>
            <h5>{{ $t("Letzte Anmeldung") }}:</h5>
            <h6 class="ml-3">{{ time(last_login) }}</h6>
            <h5>{{ $t("Passwort") }}:</h5>
            <b-form-input
              v-model="new_password"
              type="password"
              name="new_password"
              autocomplete="current-password"
              value
            ></b-form-input>
            <div class="text-right">
              <button
                class="btn btn-md btn-primary mt-3 mx-0"
                type="submit"
                name="button"
              >
                {{ $t("Aktualisieren") }}
              </button>
            </div>
          </form>
          <h4>{{ $t("Adressen") }}:</h4>
          <table
            class="table table-striped table-borderless table-sm"
            :class="'table-' + theme()"
          >
            <thead class="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">{{ $t("Name") }}</th>
                <th scope="col">{{ $t("Adresse") }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in user.addresses" :key="item.id">
                <th
                  scope="row"
                  @click="goToAddress(item.id)"
                  class="pointer color"
                >
                  {{ item.id }}
                </th>
                <td @click="goToAddress(item.id)" class="pointer">
                  {{ item.firstName }} {{ item.lastName }}
                </td>
                <td @click="goToAddress(item.id)" class="pointer">
                  {{ item.street }}<br />{{ item.postCode }} {{ item.city }}
                </td>
                <td>
                  <div @click="deleteAddress(item.id)">
                    <BaseIcon
                      name="trash-2"
                      width="25"
                      height="25"
                      class="mt-2"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <button
            class="btn btn-lg btn-block btn-primary mt-3 mx-0"
            type="button"
            @click="new_address()"
          >
            {{ $t("Neue Adresse") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DataService from "@/services/DataService";

export default {
  name: "page-account",
  data() {
    return {
      name: "",
      last_login: "",
      new_password: "",
      user: [],
      error: null,
      success: null,
    };
  },
  computed: {
    ...mapGetters(["userData", "companyData"]),
  },
  created: function () {
    DataService.getUser(this.companyData.apiKey, this.userData.token).then(
      (response) => {
        this.user = response.data;
        this.name = response.data.loginName;
        this.last_login = response.data.lastLogin;
      }
    );
  },
  methods: {
    updateUser() {
      if (this.new_password != "") {
        this.$store
          .dispatch("updateUser", {
            password: this.new_password,
          })
          .then(() => {
            this.success = "Update Erfolgreich!";
            this.error = false;
            setTimeout(() => {
              this.$store.dispatch("logout");
            }, 2000);
          })
          .catch((err) => {
            this.error = err.response.data;
          });
      } else {
        this.error = "Passwort fehlt!";
      }
    },
    deleteAddress(itemId) {
      if (confirm("This cannot be undone!")) {
        DataService.userAddress({
          id: itemId,
          delete: true,
        })
          .then(() => {
            this.success = "Löschung Erfolgreich!";
            this.error = false;
            setTimeout(() => {
              this.$router.go();
            }, 300);
          })
          .catch((err) => {
            this.error = err.response.data;
          });
      }
    },
    goToAddress(itemId) {
      this.$router.push({ name: "userAddress", params: { addressId: itemId } });
    },
    new_address() {
      this.$router.push({ name: "newAddress" });
    },
    time(value) {
      var a = new Date(value);
      var year = a.getFullYear();
      var month = a.getMonth() + 1;
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();
      if (min.toString().length == 1) {
        min = "0" + min;
      }
      var time = date + "/" + month + "/" + year + " " + hour + ":" + min;

      return time;
    },
    goHome() {
      setTimeout(() => {
        this.$router.push({ name: "order" });
      }, 300);
    },
    theme() {
      var hexcolor = this.companyData.shopBackgroundColorRgb.replace("#", "");
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "light" : "dark";
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.noWrap {
  white-space: nowrap;
}
.doWrap {
  word-wrap: break-word;
}
</style>
