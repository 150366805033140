<template>
  <div @click="removeFromCart(product)">
    <BaseIcon name="trash-2" />
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "app-removefromcart",
  props: {
    product: Object,
  },
  methods: mapActions(["removeFromCart"]),
};
</script>

<style scoped></style>
