<template>
  <footer id="sticky-footer">
    <div class="w-100 text-center">
      <span>Copyright &copy; 2020 Xeno-Data GmbH</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
};
</script>

<style scoped></style>
