import DataService from "@/services/DataService.js";

export const getProducts = ({ commit }, credentials) => {
  DataService.getAllProducts(credentials.key).then((response) => {
    commit("RECEIVE_PRODUCTS", response.data);
  });
};

export const addToCart = ({ commit }, cartItem) => {
  if (cartItem.soldOut == false) {
    commit("ADD_TO_CART", cartItem);
  }
};

export const removeFromCart = ({ commit }, cartItem) => {
  commit("REMOVE_FROM_CART", cartItem);
};

export const removeAllFromCart = ({ commit }) => {
  commit("REMOVE_ALL_FROM_CART");
};

export const setOrder = ({ commit }, orderData) => {
  commit("SET_ORDER", orderData);
};

export const clearOrder = ({ commit }) => {
  commit("CLEAR_ORDER");
};

export const register = ({ commit }, credentials) => {
  return DataService.userRegister(credentials).then((response) => {
    commit("SET_USER_DATA", response.data);
  });
};

export const login = ({ commit }, credentials) => {
  return DataService.userLogin(credentials).then((response) => {
    commit("SET_USER_DATA", response.data);
  });
};

export const updateUser = ({ commit }, credentials) => {
  return DataService.updateUser(credentials).then((response) => {
    commit("SET_USER_DATA", response.data);
  });
};

export const userActivate = ({ commit }, credentials) => {
  return DataService.activateUser(credentials).then((response) => {
    commit("SET_USER_DATA", response.data);
  });
};

export const refreshUser = ({ commit }, credentials) => {
  commit("SET_USER_DATA", credentials);
};

export const logout = ({ commit }) => {
  commit("CLEAR_USER_DATA");
};

export const clearStore = ({ commit }) => {
  commit("CLEAR_STORE");
};

export const setStore = ({ commit }, credentials) => {
  return DataService.getStore(credentials.apikey).then((response) => {
    response.data.allow_selection = credentials.allow_stores;
    response.data.tableNumber = credentials.tableNumber;
    commit("SET_STORE", response.data);
  });
};
