export const cartProducts = (state) => {
  return state.products.added.map(
    ({ id, totalPrice, selectedOptions, quantity, note }) => {
      const cartItem = state.products.all.find((product) => product.id === id);
      return {
        ...cartItem,
        totalPrice,
        selectedOptions,
        quantity,
        note,
      };
    }
  );
};

export const itemsQuantity = (state) => {
  return cartProducts(state).reduce((quantity, item) => {
    return quantity + item.quantity;
  }, 0);
};

export const total = (state) => {
  const sum = cartProducts(state).reduce((subtotal, item) => {
    return subtotal + item.totalPrice;
  }, 0);
  return sum;
};

export const taxes = (state) => (total(state) / 1.19) * 0.19;

export const loggedIn = (state) => {
  return !!state.users.user;
};

export const activated = (state) => {
  return state.users.user.isActivated;
};

export const userData = (state) => {
  return state.users.user;
};

export const orderData = (state) => {
  return state.order.order;
};

export const companyData = (state) => {
  return state.companies.company;
};

export const allowStoreSelection = (state) => {
  return state.companies.company.allow_selection;
};

export const storeRows = (state) => {
  return state.companies.company.dispayColumns;
};
