<template>
  <router-link
    class="router-link"
    :to="{ name: 'productPage', params: { productId: product.id } }"
  >
    <div class="product_card panel t1 mb-n2">
      <div class="d-flex justify-content-between my-1">
        <div class="m-0 p-2">
          <img v-bind:src="product.imageUrl" @error="replaceByDefault" />
        </div>
        <div class="m-0 p-2 w-100">
          <div class>
            <h5 v-if="translate == 1 && product.name1">
              {{ product.name1 }}
            </h5>
            <h5 v-else-if="translate == 2 && product.name2">
              {{ product.name2 }}
            </h5>
            <h5 v-else-if="translate == 3 && product.name3">
              {{ product.name3 }}
            </h5>
            <h5 v-else>{{ product.name }}</h5>
            <p
              style="white-space: pre-line"
              v-if="product.productInfoTransLated"
            >
              {{ productInfo(product.productInfoTransLated) }}
            </p>
          </div>
        </div>
        <div class="m-0 p-2">
          <h5 class="price color">
            {{ totalPrice | currency(cSymb) }}
          </h5>
        </div>
      </div>
      <span class="ausverkauft" v-if="product.soldOut">
        {{ $t("AUSVERKAUFT") }}
      </span>
      <div v-if="product.promotion" class="ribbon ribbon-bottom-right">
        <span>{{ $t("ANGEBOT") }}</span>
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
export default {
  name: "app-product",
  props: {
    product: Object,
  },
  data() {
    return {
      productOptions: Object,
      productInformation: "",
    };
  },
  created: function () {
    var creOpt = this.product.optionGroups;
    this.productOptions = [];
    if (creOpt) {
      for (var i = 0; i < creOpt.length; i++) {
        this.productOptions.push({
          id: creOpt[i].id,
          name: creOpt[i].name,
          multiselect: creOpt[i].multiselect,
          options: [],
        });
        for (var s = 0; s < creOpt[i].options.length; s++) {
          this.productOptions[i].options.push({
            id: creOpt[i].options[s].id,
            name: creOpt[i].options[s].name,
            name1: creOpt[i].options[s].name1,
            name2: creOpt[i].options[s].name2,
            addPrice: creOpt[i].options[s].addPrice,
            optionQuantity: 0,
          });
        }
      }
    }
  },

  computed: {
    ...mapGetters(["companyData"]),
    cSymb() {
      if (this.companyData.storeCurrency === "EUR") {
        return "€";
      } else if (this.companyData.storeCurrency === "USD") {
        return "$";
      } else {
        return this.companyData.storeCurrency;
      }
    },
    translate() {
      var lang = Vue.i18n.locale();
      var index = this.companyData.languages.findIndex((x) => x === lang);
      return index;
    },
    selectedOptions() {
      var selOpt = this.productOptions;
      var boston = new Array();
      for (var i = 0; i < selOpt.length; i++) {
        boston.push({
          id: selOpt[i].id,
          name: selOpt[i].name,
          multiselect: selOpt[i].multiselect,
          options: [],
        });
        for (var s = 0; s < selOpt[i].options.length; s++) {
          if (selOpt[i].options[s].optionQuantity > 0) {
            boston[i].options.push({
              id: selOpt[i].options[s].id,
              name: selOpt[i].options[s].name,
              name1: selOpt[i].options[s].name1,
              name2: selOpt[i].options[s].name2,
              addPrice: selOpt[i].options[s].addPrice,
              optionQuantity: selOpt[i].options[s].optionQuantity,
            });
          }
        }
      }
      boston = boston.filter((item) => item.options.length > 0);
      return boston;
    },
    totalPrice() {
      var optionPrice = 0;
      if (this.productOptions.length) {
        for (var i = 0; i < this.productOptions.length; i++) {
          var mslct = this.productOptions[i].multiselect;
          if (mslct == false) {
            optionPrice += this.productOptions[i].options[0]["addPrice"];
          }
        }
      }
      return this.product.price + optionPrice;
    },
    cartItem() {
      var cartItem = new Object();
      cartItem["id"] = this.product.id;
      cartItem["name"] = this.product.name;
      cartItem["name1"] = this.product.name1;
      cartItem["name2"] = this.product.name2;
      cartItem["price"] = this.product.price;
      cartItem["soldOut"] = this.product.soldOut;
      cartItem["promotion"] = this.product.promotion;
      cartItem["calories"] = this.product.calories;
      cartItem["groupId"] = this.product.groupId;
      cartItem["groupName"] = this.product.groupName;
      cartItem["groupName1"] = this.product.groupName1;
      cartItem["groupName2"] = this.product.groupName2;
      cartItem["ean"] = this.product.ean;
      cartItem["selectedOptions"] = this.selectedOptions;
      cartItem["totalPrice"] = this.totalPrice;
      return cartItem;
    },
  },
  methods: {
    ...mapActions(["addToCart"]),
    productInfo(val) {
      var lang = Vue.i18n.locale();
      var i = this.companyData.languages.findIndex((x) => x === lang);
      var info = val.split("|");
      var infotext = info[i];
      if (val.indexOf("|") > 0) {
        if (infotext.length > 80) {
          return infotext.substring(0, 80) + " ...";
        } else {
          return infotext;
        }
      } else {
        return val;
      }
    },
    replaceByDefault(e) {
      e.target.src = "/assets/img/no_image.png";
    },
  },
};
</script>

<style scoped>
.price {
  white-space: nowrap;
}
</style>
