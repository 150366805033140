<template>
  <div class="panel t1 text-center p-2">
    <h3 class="mb-0">{{ $t("Gesamtsumme") }}: {{ total | currency(cSymb) }}</h3>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "app-cartsummary",
  computed: {
    ...mapGetters([
      "itemsQuantity",
      "subtotal",
      "taxes",
      "total",
      "companyData",
    ]),
    cSymb() {
      if (this.companyData.storeCurrency === "EUR") {
        return "€";
      } else if (this.companyData.storeCurrency === "USD") {
        return "$";
      } else {
        return this.companyData.storeCurrency;
      }
    },
  },
};
</script>

<style scoped></style>
