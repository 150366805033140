<template>
  <div>
    <h2 id="title">{{ $t("Karte") }}</h2>
    <div class="row">
      <div class="col-lg-2 px-1 mt-1 d-none d-xl-block">
        <div v-for="(item, index) in itemGroups" :key="index">
          <div
            v-scroll-to="{ el: '#title', offset: -200 }"
            class="group-card t1 mb-1"
            :class="item === groupName ? 'active' : ''"
            @click="updateProducts(item)"
          >
            <div class="text-center pt-2">
              <h5 class="grtxt mb-0">{{ $t(productGroupName(item)) }}</h5>
            </div>
          </div>
        </div>
        <div
          v-scroll-to="{ el: '#title', offset: -200 }"
          class="group-card t1 mb-1"
          :class="groupName === 'all' ? 'active' : ''"
          @click="updateProducts('all')"
        >
          <div class="text-center pt-2">
            <h5 class="mb-0">{{ $t("Alles") }}</h5>
          </div>
        </div>
      </div>

      <div class="col px-3 m-0">
        <div
          class="navPill_container text-center mx-n3 d-none d-md-block d-sm-block d-lg-block d-xl-none"
        >
          <div class="navPill panel t1" @click="scroll_right">
            <BaseIcon
              name="chevron-left"
              width="20"
              height="20"
              class="navPill-icon"
            />
          </div>
          <div
            class="navPill t1 mb-1"
            :class="item === groupName ? 'active' : ''"
            v-for="(item, index) in itemGroups"
            :key="index"
            :groupName="groupName"
            @click="updateProducts(item)"
          >
            {{ $t(productGroupName(item)) }}
          </div>
          <div
            class="navPill t1 mb-1"
            :class="groupName === 'all' ? 'active' : ''"
            @click="updateProducts('all')"
          >
            {{ $t("Alles") }}
          </div>
          <div class="navPill panel t1" @click="scroll_left">
            <BaseIcon
              name="chevron-right"
              width="20"
              height="20"
              class="navPill-icon"
            />
          </div>
        </div>
        <div v-if="groupName === 'all'">
          <h3 class="color">{{ $t("Alles") }}</h3>
          <transition name="slide-fade" mode="out-in">
            <div>
              <div
                v-for="(item, index) in itemGroups"
                :key="index"
                class="m-0 p-0"
              >
                <h4>{{ $t(productGroupName(item)) }}</h4>
                <productGroup :group="productGroup(item)" :key="componentKey" />
              </div>
            </div>
          </transition>
        </div>
        <div v-else>
          <h3 class="color">{{ $t(productGroupName(groupName)) }}</h3>
          <transition name="slide-fade" mode="out-in">
            <productGroup
              :group="productGroup(groupName)"
              :key="componentKey"
            />
          </transition>
        </div>
      </div>
      <div class="col-lg-3 px-1 mt-1 d-none d-xl-block">
        <ShoppingCart />
      </div>
    </div>
  </div>
</template>

<script>
import productGroup from "@/components/productGroup.vue";
import ShoppingCart from "@/components/shopping-cart/ShoppingCart";
import { mapState, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "app-orderview",
  components: {
    productGroup,
    ShoppingCart,
  },
  data() {
    return {
      componentKey: 0,
      groupName: "all",
    };
  },
  created() {
    if (this.$route.params.grpNme) {
      this.groupName = this.$route.params.grpNme;
    }
  },
  computed: {
    ...mapState({
      products: (state) => state.products.all,
    }),
    ...mapGetters(["companyData"]),

    itemGroups() {
      var pdts = this.products;
      var spdts = pdts.sort((a, b) => {
        return a.groupSort - b.groupSort;
      });
      return (pdts = [...new Set(spdts.map(({ groupName }) => groupName))]);
    },

    tableNumber() {
      return this.companyData.tableNumber;
    },
  },
  methods: {
    productGroup(m) {
      return this.products.filter((item) => item.groupName === m);
    },
    productGroupName(m) {
      var lang = Vue.i18n.locale();
      var index = this.companyData.languages.findIndex((x) => x === lang);
      var gn = this.products.find((item) => item.groupName === m);
      if (index == 1 && gn.groupName1) {
        return gn.groupName1;
      } else if (index == 2 && gn.groupName2) {
        return gn.groupName2;
      } else if (index == 3 && gn.groupName3) {
        return gn.groupName3;
      } else {
        return m;
      }
    },
    updateProducts(val) {
      this.groupName = val;
      this.componentKey += 1;
    },
    scroll_left() {
      let content = document.querySelector(".navPill_container");
      content.scrollLeft -= 5000;
    },
    scroll_right() {
      let content = document.querySelector(".navPill_container");
      content.scrollLeft += 5000;
    },
  },
};
</script>

<style scoped>
.grtxt {
  padding-left: 5px;
  width: 152px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
