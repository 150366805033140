<template>
  <div class="row mb-4">
    <div class="p-1 w-100" v-for="product in Products" :key="product.id">
      <Product :product="product" />
    </div>
  </div>
</template>

<script>
import Product from "@/components/Product";

export default {
  name: "app-productgroup",
  components: {
    Product,
  },
  props: {
    group: Array,
  },
  data() {
    return {
      window: {
        height: 0,
      },
      document: {
        height: 0,
      },
    };
  },
  computed: {
    Products() {
      var pdts = this.group.filter((item) => item.articleType == 0);
      return pdts.sort((a, b) => {
        return a.articleSort - b.articleSort;
      });
    },
  },
};
</script>

<style scoped>
.product_image {
  max-width: 250px;
}
</style>
