<template>
  <div>
    <h2>{{ $t("Passwort Zurücksetzen") }}</h2>
    <div class="row">
      <div class="col-lg-12">
        <div class="alert alert-danger" v-if="error">{{ $t(error) }}</div>
        <div class="alert alert-success" v-if="success">
          {{
            $t(
              "Ein neues temporäres Passwort wurde an Ihre E-Mail-Adresse gesendet!"
            )
          }}
        </div>
        <form @submit.prevent="reset" autocomplete="off">
          <div class="panel p-2 mb-4">
            <h5>Email:</h5>
            <b-form-input
              v-model="email"
              type="email"
              name="email"
              autocomplete="current-email"
              required
              value
            ></b-form-input>
            <button
              class="btn btn-lg btn-right btn-primary mt-2"
              type="submit"
              name="button"
            >
              {{ $t("Abschicken") }}
            </button>
          </div>
        </form>
        <router-link to="/login" class="text-right color"
          ><h5>{{ $t("Zurück zur Anmledung") }}</h5></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import DataService from "@/services/DataService";
export default {
  name: "page-resetpassword",
  data() {
    return {
      email: "",
      error: null,
      success: null,
    };
  },
  methods: {
    reset() {
      DataService.pwdReset(this.email)
        .then(() => {
          this.success = true;
          this.error = false;
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 2000);
        })
        .catch((err) => {
          this.erro = err.response.statusText;
          this.error =
            "Die angegebene Email Adresse befindet sich nicht in unserer Datenbank!";
          setTimeout(() => {
            this.$router.go();
          }, 2000);
        });
    },
  },
};
</script>

<style scoped></style>
