<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="cart panel t1 p-2">
        <ShoppingCartSummary />
        <hr />
        <BaseIcon name="target" width="22" height="22" class="mt-2 mr-1" />
        <h2>{{ $t("Warenkorb") }}</h2>

        <div v-if="items.length">
          <div class="panel t1 p-1 mb-2">
            <ShoppingCartItemExt
              v-for="(item, index) in items"
              :item="item"
              :key="index"
            />
          </div>

          <div class="d-flex justify-content-between mb-2">
            <div class="m-0">
              <button
                class="btn btn-md btn-secondary m-0 d-none d-md-block d-sm-block d-lg-none"
                @click="goBack()"
              >
                {{ $t("Abbrechen") }}
              </button>
            </div>
            <div class="m-0 p-0">
              <button class="btn btn-md btn-secondary m-0" @click="emptyCart">
                {{ $t("Leeren") }}
              </button>
            </div>
          </div>

          <button
            class="btn btn-lg btn-secondary btn-block m-0"
            @click="checkOut"
          >
            {{ $t("Bezahlen") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShoppingCartItemExt from "@/components/shopping-cart/ShoppingCartItemExt";
import ShoppingCartSummary from "@/components/shopping-cart/ShoppingCartSummary";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "app-shoppingcart",
  computed: {
    ...mapState({ products: (state) => state.products.all }),
    ...mapGetters({ items: "cartProducts" }),
    ...mapGetters([
      "itemsQuantity",
      "subtotal",
      "taxes",
      "total",
      "companyData",
    ]),
    cSymb() {
      if (this.companyData.storeCurrency === "EUR") {
        return "€";
      } else if (this.companyData.storeCurrency === "USD") {
        return "$";
      } else {
        return this.companyData.storeCurrency;
      }
    },
  },
  components: {
    ShoppingCartItemExt,
    ShoppingCartSummary,
  },
  data() {
    return {
      min_orderValue: 0,
    };
  },
  created() {
    this.getProducts({ key: this.companyData.menuIdDelivery });
    this.min_orderValue = this.companyData.minimumOrderValue;
  },
  methods: {
    ...mapActions(["getProducts", "removeAllFromCart"]),
    emptyCart() {
      this.removeAllFromCart();
      this.goBack();
    },
    goBack() {
      if (this.$route.matched.some(({ name }) => name === "order")) {
        null;
      } else {
        setTimeout(() => {
          this.$router.push({ name: "order" });
        }, 300);
      }
    },
    checkOut() {
      setTimeout(() => {
        this.$router.push({ name: "CheckOut" });
      }, 300);
    },
  },
};
</script>
<style scoped>
.min_orderValue {
  color: red;
  font-size: 20px;
}
</style>
