const state = {
  all: [],
  added: [],
};

const mutations = {
  RECEIVE_PRODUCTS(state, products) {
    state.all = products;
  },

  ADD_TO_CART(state, item) {
    state.added.push({
      id: item.id,
      totalPrice: item.totalPrice,
      selectedOptions: item.selectedOptions,
      quantity: item.productQuantity,
      note: item.productNote,
    });
  },

  REMOVE_FROM_CART(state, item) {
    const index = state.added.findIndex((added) => added.id === item.id);
    state.added.splice(index, 1);
  },

  REMOVE_ALL_FROM_CART(state) {
    var cartItems = state.added;
    for (var i = 0; i < cartItems.length; i++) {
      const index = state.added.findIndex(
        (added) => added.id === cartItems[i].id
      );
      state.added.splice(index, 1);
      i = -1;
    }
  },
};

export default {
  state,
  mutations,
};
