<template>
  <div class="panel p-2 noselect">
    <div class="panel t1 mb-2 p-2">
      <div v-if="product.promotion" class="ribbon ribbon-bottom-left">
        <span>{{ $t("ANGEBOT") }}</span>
      </div>
      <div class="row">
        <div class="col-lg-5">
          <div class="px-2 d-md-none d-lg-block">
            <div>
              <BaseIcon
                name="target"
                width="22"
                height="22"
                class="mt-1 mr-1"
              />
              <h3 v-if="langIndex == 1 && product.name1">
                {{ product.name1 }}
              </h3>
              <h3 v-else-if="langIndex == 2 && product.name2">
                {{ product.name2 }}
              </h3>
              <h3 v-else-if="langIndex == 3 && product.name3">
                {{ product.name3 }}
              </h3>
              <h3 v-else>{{ product.name }}</h3>
            </div>
            <div class="mb-2">
              <div class="text-center m-0">
                <img
                  v-bind:src="product.imageUrl"
                  @error="replaceByDefault"
                  class="product_image rounded"
                />
              </div>
            </div>
            <div class="text-right">
              <h2 class="color">
                <strong>{{ totalPrice | currency(cSymb) }}</strong>
              </h2>
            </div>
            <div class="text-left">
              <p
                v-if="product.productInfoTransLated"
                style="white-space: pre-line"
              >
                {{ translate(product.productInfoTransLated) }}
              </p>
            </div>
          </div>
          <div class="px-2 d-none d-md-block d-lg-none">
            <div>
              <BaseIcon
                name="target"
                width="22"
                height="22"
                class="mt-1 mr-1"
              />
              <h3 v-if="langIndex == 1 && product.name1">
                {{ product.name1 }}
              </h3>
              <h3 v-else-if="langIndex == 2 && product.name2">
                {{ product.name2 }}
              </h3>
              <h3 v-else-if="langIndex == 3 && product.name3">
                {{ product.name3 }}
              </h3>
              <h3 v-else>{{ product.name }}</h3>
            </div>
            <table width="100%">
              <tr>
                <td width="20%">
                  <div class="mb-2">
                    <div class="text-center m-0">
                      <img
                        v-bind:src="product.imageUrl"
                        @error="replaceByDefault"
                        style="width: 100%"
                        class="rounded"
                      />
                    </div>
                  </div>
                </td>
                <td width="80%" style="padding-left: 15px">
                  <div class="text-right">
                    <h2 class="color">
                      <strong>{{ totalPrice | currency(cSymb) }}</strong>
                    </h2>
                  </div>
                  <div class="text-left">
                    <p
                      v-if="product.productInfoTransLated"
                      style="white-space: pre-line"
                    >
                      {{ translate(product.productInfoTransLated) }}
                    </p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="col-lg-7">
          <table width="100%">
            <tr>
              <td width="40%">
                <span
                  class="option-button p-0 ml-1"
                  v-on:click="productQuantity > 1 ? (productQuantity -= 1) : ''"
                >
                  <BaseIcon name="minus" class="m-auto" />
                </span>
                <b-form-input
                  class="option-quantity p-0 ml-1 noselect"
                  size="lg"
                  v-model="productQuantity"
                  disabled
                ></b-form-input>
                <span
                  class="option-button p-0 ml-1"
                  v-on:click="
                    productQuantity < 10 ? (productQuantity += 1) : ''
                  "
                >
                  <BaseIcon name="plus" class="m-auto" />
                </span>
              </td>
              <td width="60%" align="right" style="padding-right: 5px">
                <button
                  v-if="onDuty"
                  type="button"
                  class="btn btn-lg btn-primary btn-block m-0"
                  :disabled="product.soldOut"
                  @click="
                    addToCart(cartItem);
                    goBack();
                  "
                >
                  {{ "Bestellen" | translate }}
                </button>
              </td>
            </tr>
          </table>

          <div v-if="productOptions.length" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle.accordion-2
                  variant="secondary"
                  class="btn-lg mx-0"
                  >{{ $t("Optionen") }}</b-button
                >
              </b-card-header>
              <b-collapse
                id="accordion-2"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text class="text-center">
                    <div
                      v-for="(prodOpt, parent_index) in productOptions"
                      :key="parent_index"
                    >
                      <h4 class="my-1">
                        {{ translate(prodOpt.nameTranslated) }}
                      </h4>
                      <div
                        class="row sides py-1"
                        v-for="(subOpt, index) in prodOpt.options"
                        :key="index"
                      >
                        <div class="col-lg-12 px-1">
                          <div
                            class="check-button-unchecked"
                            :ref="'subOptionBtn' + subOpt.id"
                            @click="
                              PlusOne(prodOpt.multiselect, parent_index, index)
                            "
                          >
                            <BaseIcon
                              name="check"
                              width="24"
                              height="24"
                              class="check-icon m-auto"
                            />
                          </div>
                          <b-form-input
                            :name="subOpt.name"
                            :ref="'subOption' + subOpt.id"
                            v-model="subOpt.optionQuantity"
                            hidden
                          ></b-form-input>
                          <span class="product_option m-2"
                            ><span
                              class="optionCut"
                              v-if="langIndex == 1 && subOpt.name1"
                            >
                              {{ subOpt.name1 }}
                            </span>
                            <span
                              class="optionCut"
                              v-else-if="langIndex == 2 && subOpt.name2"
                            >
                              {{ subOpt.name2 }}
                            </span>
                            <span
                              class="optionCut"
                              v-else-if="langIndex == 3 && subOpt.name3"
                            >
                              {{ subOpt.name3 }}
                            </span>
                            <span class="optionCut" v-else>
                              {{ subOpt.name }}
                            </span>
                            ( {{ subOpt.addPrice | currency(cSymb) }} )</span
                          >
                        </div>
                      </div>
                    </div>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div v-if="allowNotes" class="panel t1 p-2 mt-2">
            <h4>{{ $t("Anmerkung") }}:</h4>
            <b-form-textarea
              v-model="productNote"
              class="mb-3"
              style="font-size: 20px"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="m-0 text-right">
      <button
        class="btn btn-lg btn-secondary m-0"
        type="button"
        @click="goBack()"
      >
        {{ $t("Abbrechen") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DataService from "@/services/DataService";
import Vue from "vue";
export default {
  name: "app-singleproduct",
  props: {
    product: Object,
  },
  data() {
    return {
      productOptions: Object,
      productQuantity: 1,
      productNote: "",
    };
  },
  created: function () {
    var creOpt = this.product.optionGroups;
    this.productOptions = [];
    if (creOpt) {
      for (var i = 0; i < creOpt.length; i++) {
        this.productOptions.push({
          id: creOpt[i].id,
          name: creOpt[i].name,
          nameTranslated: creOpt[i].nameTranslated,
          multiselect: creOpt[i].multiselect,
          options: [],
        });
        for (var s = 0; s < creOpt[i].options.length; s++) {
          this.productOptions[i].options.push({
            id: creOpt[i].options[s].id,
            name: creOpt[i].options[s].name,
            name1: creOpt[i].options[s].name1,
            name2: creOpt[i].options[s].name2,
            name3: creOpt[i].options[s].name3,
            addPrice: creOpt[i].options[s].addPrice,
            optionQuantity: 0,
          });
        }
      }
    }
  },
  computed: {
    ...mapGetters(["companyData"]),
    allowNotes() {
      return this.companyData.allowNotes;
    },
    cSymb() {
      if (this.companyData.storeCurrency === "EUR") {
        return "€";
      } else if (this.companyData.storeCurrency === "USD") {
        return "$";
      } else {
        return this.companyData.storeCurrency;
      }
    },
    langIndex() {
      var lang = Vue.i18n.locale();
      var index = this.companyData.languages.findIndex((x) => x === lang);
      return index;
    },
    onDuty() {
      if (this.companyData.allowCheckout24) {
        return true;
      } else {
        if (this.companyData.onDuty == "closed") {
          return false;
        } else {
          return true;
        }
      }
    },
    selectedOptions() {
      var selOpt = this.productOptions;
      var boston = new Array();
      for (var i = 0; i < selOpt.length; i++) {
        boston.push({
          id: selOpt[i].id,
          name: selOpt[i].name,
          nameTranslated: selOpt[i].nameTranslated,
          multiselect: selOpt[i].multiselect,
          options: [],
        });
        for (var s = 0; s < selOpt[i].options.length; s++) {
          if (selOpt[i].options[s].optionQuantity > 0) {
            boston[i].options.push({
              id: selOpt[i].options[s].id,
              name: selOpt[i].options[s].name,
              name1: selOpt[i].options[s].name1,
              name2: selOpt[i].options[s].name2,
              name3: selOpt[i].options[s].name3,
              addPrice: selOpt[i].options[s].addPrice,
              optionQuantity: this.productQuantity,
            });
          }
        }
      }
      boston = boston.filter((item) => item.options.length > 0);
      return boston;
    },
    totalPrice() {
      var sum = 0;
      var pOpt = this.productOptions;
      for (var i = 0; i < pOpt.length; i++) {
        for (var s = 0; s < pOpt[i].options.length; s++) {
          sum +=
            pOpt[i].options[s].optionQuantity * pOpt[i].options[s].addPrice;
        }
      }
      return (this.product.price + sum) * this.productQuantity;
    },
    cartItem() {
      var cartItem = new Object();
      cartItem["id"] = this.product.id;
      cartItem["name"] = this.product.name;
      cartItem["name1"] = this.product.name1;
      cartItem["name2"] = this.product.name2;
      cartItem["name3"] = this.product.name3;
      cartItem["price"] = this.product.price;
      cartItem["soldOut"] = this.product.soldOut;
      cartItem["promotion"] = this.product.promotion;
      cartItem["calories"] = this.product.calories;
      cartItem["groupId"] = this.product.groupId;
      cartItem["groupName"] = this.product.groupName;
      cartItem["groupName1"] = this.product.groupName1;
      cartItem["groupName2"] = this.product.groupName2;
      cartItem["groupName3"] = this.product.groupName3;
      cartItem["ean"] = this.product.ean;
      cartItem["selectedOptions"] = this.selectedOptions;
      cartItem["productQuantity"] = this.productQuantity;
      cartItem["totalPrice"] = this.totalPrice;
      cartItem["productNote"] = this.productNote;
      return cartItem;
    },
  },
  methods: {
    ...mapActions(["addToCart"]),
    productInfo(val) {
      var lang = Vue.i18n.locale();
      var index = this.companyData.languages.findIndex((x) => x === lang);
      var info = val.split("|");
      return info[index];
    },
    translate(val) {
      var lang = Vue.i18n.locale();
      var i = this.companyData.languages.findIndex((x) => x === lang);
      var info = val.split("|");
      var infotext = info[i];
      if (val.indexOf("|") > 0) {
        return infotext;
      } else {
        return val;
      }
    },
    GetArticleImageUrl(id) {
      return DataService.getArticleImageUrl(id);
    },
    PlusOne(mslct, parent_index, index) {
      var id = this.productOptions[parent_index].options[index]["id"];
      var options = this.productOptions[parent_index].options;
      var el = "subOption" + id;
      var btn = "subOptionBtn" + id;
      var orderState = this.$refs[el][0].$refs.input.value;
      if (orderState == 0) {
        this.productOptions[parent_index].options[index]["optionQuantity"] = 1;
        this.$refs[btn][0].classList.add("check-button-checked");
        if (mslct == false) {
          for (var i = 0; i < options.length; i++) {
            var bli = "subOptionBtn" + options[i].id;
            if (options[i].id !== id) {
              this.productOptions[parent_index].options[i][
                "optionQuantity"
              ] = 0;
              this.$refs[bli][0].classList.remove("check-button-checked");
            }
          }
        }
      } else if (orderState == 1 && mslct == true) {
        this.productOptions[parent_index].options[index]["optionQuantity"] = 0;
        this.$refs[btn][0].classList.remove("check-button-checked");
      }
    },
    goBack() {
      setTimeout(() => {
        this.$router.push({
          name: "order",
          params: { grpNme: this.product.groupName },
        });
      }, 300);
    },
    replaceByDefault(e) {
      e.target.src = "/assets/img/no_image.png";
    },
  },
  mounted() {
    if (this.productOptions.length) {
      for (var i = 0; i < this.productOptions.length; i++) {
        var id = this.productOptions[i].options[0]["id"];
        var mslct = this.productOptions[i].multiselect;
        var btn = "subOptionBtn" + id;
        if (mslct == false) {
          this.productOptions[i].options[0]["optionQuantity"] = 1;
          this.$refs[btn][0].classList.add("check-button-checked");
        } else {
          for (var s = 0; s < this.productOptions[i].options.length; s++) {
            this.productOptions[i].options[s]["optionQuantity"] = 0;
          }
        }
      }
    }
  },
};
</script>

<style scoped>
.product_image {
  max-width: 250px;
}
</style>
